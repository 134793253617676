import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from 'components/Layout'
import Item from './Item'

const GridDropdown = ({ faqs, selectedId }) => {
  return (
    <Grid width={['100%']} gridGap="m">
      {faqs.map(({ title, description, id }, key) => (
        <Item key={key.toString()} id={id} title={title} description={description} selectedId={selectedId} />
      ))}
    </Grid>
  )
}

GridDropdown.defaultProps = {
  selectedId: '',
}

GridDropdown.propTypes = {
  faqs: PropTypes.array.isRequired,
  selectedId: PropTypes.string,
}

export default GridDropdown
