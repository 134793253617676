import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { COLOR_CONSTANTS } from 'theme'
import { Text, H4 } from 'components/Typography'
import { Box, Flex } from 'components/Layout'
import plus from 'static/svg/plus_rounded.svg'
import minus from 'static/svg/minus_rounded.svg'

const StyledItem = styled(Flex)`
  background: ${COLOR_CONSTANTS.WHITE};
  box-shadow: 0px 0px 38px rgba(45, 71, 120, 0.12);
  border-radius: 12px;
`

const TitleWrapper = styled(Flex)`
  cursor: pointer;
`

const DescriptionWrapper = styled(Text)`
  white-space: pre-line;
`

const Item = ({ id, title, description, selectedId }) => {
  const [isOpen, setIsOpen] = useState(id === selectedId)
  const itemRef = useRef(null)

  useEffect(() => {
    if (id === selectedId) {
      window.scrollTo(0, itemRef.current.offsetTop - 85)
    }
  }, [])

  const togglePanel = () => {
    setIsOpen(!isOpen)
  }

  return (
    <StyledItem p="m" width="100%" flexDirection="column" ref={itemRef}>
      <Flex width="100%" px="l" py="s" flexDirection="column">
        <TitleWrapper onClick={togglePanel} alignItems="center" justifyContent="space-between">
          <H4 fontWeight="bold" fontSize="l" textAlign="left" color={COLOR_CONSTANTS.DENIM}>
            {title}
          </H4>
          <Box>
            {isOpen ? (
              <img src={minus} alt="" width="24px" height="24px" />
            ) : (
              <img src={plus} alt="" width="24px" height="24px" />
            )}
          </Box>
        </TitleWrapper>

        <Flex mt="m" className="transition" display={isOpen ? 'flex' : 'none'}>
          <DescriptionWrapper
            fontSize="m"
            textAlign="left"
            dangerouslySetInnerHTML={{ __html: description }}
            color="secondaryText"
          />
        </Flex>
      </Flex>
    </StyledItem>
  )
}
Item.defaultProps = {
  selectedId: '',
}
Item.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  selectedId: PropTypes.string,
}

export default Item
