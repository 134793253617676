import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import App from 'App'
import { COLOR_CONSTANTS, space, colors, radius, lineHeights } from 'theme'
import { CONTAINER_MAX_WIDTH, FREQUENTLY_ASKED_QUESTIONS } from 'consts'
import SEO from 'components/SEO'
import { Flex, Grid, Box } from 'components/Layout'
import Container from 'components/Container'
import { Text, H1, H2, H3 } from 'components/Typography'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'

import imageHeader from 'static/images/integrations/header_picture.webp'
import imageDoubleArrowRightBlue from 'static/images/home/double_arrow_right_blue.svg'

import networkImageInstagram from 'static/images/networks/instagram.svg'
import networkImageFacebook from 'static/images/networks/facebook.svg'
import networkImageTwitter from 'static/images/networks/x.svg'
import networkImageYoutube from 'static/images/networks/youtube.svg'
import networkImageLinkedin from 'static/images/networks/linkedin.svg'
import networkImageReddit from 'static/images/networks/reddit.svg'
import networkImagePinterest from 'static/images/networks/pinterest.svg'
import networkImageGoogle from 'static/images/networks/google.svg'
import networkImageTikTok from 'static/images/networks/tiktok.svg'
import networkImageTripAdvisor from 'static/images/networks/tripadvisor.svg'
import networkImageYelp from 'static/images/networks/yelp.svg'
import networkImageTrustpilot from 'static/images/networks/trustpilot.svg'
import networkImageOpenTable from 'static/images/networks/opentable.svg'
import networkImageBitly from 'static/images/networks/bitly.svg'
import networkImageCanva from 'static/images/networks/canva.svg'
import networkImageDropbox from 'static/images/networks/dropbox.svg'
import networkImageGoogleDrive from 'static/images/networks/googledrive.svg'
import networkImageOneDrive from 'static/images/networks/onedrive.svg'
import networkImageGiphy from 'static/images/networks/giphy.svg'
import networkImagePexels from 'static/images/networks/pexels.svg'
import networkImageUnsplash from 'static/images/networks/unsplash.svg'
import networkImageZapier from 'static/images/networks/zapier.svg'
import networkImageSnapchat from 'static/images/networks/snapchat.svg'
import networkImageThreads from 'static/images/networks/threads.svg'
import networkImageTumblr from 'static/images/networks/tumblr.svg'
import networkImageMake from 'static/images/networks/make.svg'
import networkImageGoogleAnalytics from 'static/images/networks/googleanalytics.svg'
import networkImageSlack from 'static/images/networks/slack.svg'
import networkImageVistaPage from 'static/images/networks/vistapage.svg'
import networkImageBluesky from 'static/images/networks/bluesky.svg'

import GridDropdown from '../Faq/GridDropdown'
import GetStartedSection from './GetStartedSection'
import SelectPlanButton from '../Home/components/SelectPlanButton'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  overflow-x: hidden;
`

const StyledNetworkWrapper = styled(Flex)`
  box-shadow: inset 0px 0px 10px rgba(39, 70, 92, 0.25);
  border-radius: ${radius.xl};
  align-items: center;
  justify-content: center;
  background: ${({ background }) => background || colors.white};
`

const StyledNetworkItemWrapper = styled(Flex)`
  border-radius: ${radius.xxl};
  border: 1px solid ${COLOR_CONSTANTS.SALT};
  padding: ${space.m};
  cursor: pointer;
  transition: all 0.5s ease;
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0px 0px 8px rgb(145 158 171 / 20%);
  }
`

const StyledLinkText = styled(Text)`
  display: flex;
  align-items: center;
`

const StyledCategoriesWrapper = styled(Box)`
  flex-direction: column;
  border-radius: ${radius.xxl};
  background-color: ${COLOR_CONSTANTS.WHITE};
  box-shadow: 0px 8px 50px 0px rgba(118, 139, 168, 0.14), 0px 2px 4px 0px rgba(37, 64, 102, 0.08);
  padding: ${space.m};
  position: sticky;
  right: 0px;
  top: calc(70px + 4vh);
  z-index: 1;
`

const StyledCategoryItem = styled(Flex)`
  padding: ${space.sm};
  cursor: pointer;
  border-radius: ${radius.xl};

  .is-active {
    background-color: red;
  }

  &:hover {
    background-color: ${COLOR_CONSTANTS.SALT};
    p {
      color: ${COLOR_CONSTANTS.DENIM};
    }
  }
`

const StyledIntegrationItemsWrapper = styled(Flex)`
  flex-direction: column;
  border-radius: ${radius.xxl};
  background-color: ${COLOR_CONSTANTS.WHITE};
  padding: ${space.l};
  z-index: 1;
  gap: 80px;
`

const {
  SOCIAL_NETWORK_TITLE,
  SOCIAL_NETWORK_ID,
  WEBSITE_LINK_TRACKING_TITLE,
  WEBSITE_LINK_TRACKING_ID,
  REPUTATION_REVIEW_MANAGEMENT_TITLE,
  REPUTATION_REVIEW_MANAGEMENT_ID,
  DAM_ID,
  DAM_TITLE,
  INTEGRATION_API_TITLE,
  INTEGRATION_API_ID,
  WORKFLOW_ID,
  WORKFLOW_TITLE,
  BIO_ID,
  BIO_TITLE,
} = {
  SOCIAL_NETWORK_TITLE: 'Social Networks',
  SOCIAL_NETWORK_ID: 'social-network',
  WEBSITE_LINK_TRACKING_TITLE: 'Website & Link Tracking',
  WEBSITE_LINK_TRACKING_ID: 'website-link-tracking',
  REPUTATION_REVIEW_MANAGEMENT_TITLE: 'Reputation & Review Management',
  REPUTATION_REVIEW_MANAGEMENT_ID: 'reputation-review-management',
  SOCIAL_LISTENING_TITLE: 'Social Listening',
  SOCIAL_LISTENING_ID: 'social-listening',
  CREATE_IMPORT_IMAGES_TITLE: 'Create & Import Images',
  // CREATE_IMPORT_IMAGES_ID: 'create-import-images',
  DAM_ID: 'digital-asset-managemnt',
  DAM_TITLE: 'Digital Asset Management',
  INTEGRATION_API_TITLE: 'Integration and API',
  INTEGRATION_API_ID: 'integration-api',
  WORKFLOW_TITLE: 'Workflow',
  WORKFLOW_ID: 'workflow',
  BIO_ID: 'bio',
  BIO_TITLE: 'Landing Pages',
}

const CATEGORIES = [
  { id: SOCIAL_NETWORK_ID, title: SOCIAL_NETWORK_TITLE },
  { id: DAM_ID, title: DAM_TITLE },
  // { id: SOCIAL_LISTENING_ID, title: SOCIAL_LISTENING_TITLE },
  // { id: CREATE_IMPORT_IMAGES_ID, title: CREATE_IMPORT_IMAGES_TITLE },
  { id: INTEGRATION_API_ID, title: INTEGRATION_API_TITLE },
  { id: BIO_ID, title: BIO_TITLE },
  { id: REPUTATION_REVIEW_MANAGEMENT_ID, title: REPUTATION_REVIEW_MANAGEMENT_TITLE },
  { id: WEBSITE_LINK_TRACKING_ID, title: WEBSITE_LINK_TRACKING_TITLE },
  { id: WORKFLOW_ID, title: WORKFLOW_TITLE },
]

const INTEGRATION_ITEMS = [
  {
    id: SOCIAL_NETWORK_ID,
    title: SOCIAL_NETWORK_TITLE,
    description: `Vista centralizes publishing, monitoring, engagement, landing pages and analytics across the major social platforms to help you save time, improve customer experience and collaborate more effectively.`,
    networks: [
      {
        picture_url: networkImageFacebook,
        title: 'Facebook',
        type: 'Social Network',
        description:
          'Vista’s Facebook integration includes publishing, boosting, analytics, reputation and response management tools for Pages and Messenger',
        background: 'linear-gradient(225deg, #E3F4FF 0%, #D0E5FF 100%)',
        link: '/integrations/facebook',
      },
      {
        picture_url: networkImageInstagram,
        title: 'Instagram',
        type: 'Social Network',
        description:
          'Work smarter with Vista’s Instagram management tools—schedule posts, manage comments, monitor hashtags and run reports',
        background: 'linear-gradient(223.67deg, #FFCAEE 2.22%, #FFE8D4 100%)',
        link: '/integrations/instagram',
      },
      {
        picture_url: networkImageLinkedin,
        title: 'LinkedIn',
        type: 'Social Network',
        description:
          'Deliver smarter, faster and more personal social communications with powerful tools to manage your LinkedIn Company Pages.',
        background: '#DAEFFA',
        link: '/integrations/linkedin',
      },
      {
        picture_url: networkImageTikTok,
        title: 'TikTok',
        type: 'Social Network',
        description:
          'Optimize and grow your end-to-end TikTok marketing strategy with image and video scheduling, comment management and presentation-ready reports.',
        background: '#E0E2F0',
        link: '/integrations/tiktok',
      },
      {
        picture_url: networkImageYoutube,
        title: 'YouTube',
        type: 'Social Network',
        description:
          'Simplify your video publishing strategy and deepen connections with your audience on YouTube, alongside other social platforms.',
        background: '#FFEEEF',
        link: '/integrations/youtube',
      },
      {
        picture_url: networkImageTwitter,
        title: 'X (ex. Twitter)',
        type: 'Social Network',
        description:
          'Seamlessly execute social media management and social customer care on X (Twitter) from a single, scalable platform.',
        background: '#434343',
        link: '/integrations/twitter',
      },

      {
        picture_url: networkImagePinterest,
        title: 'Pinterest',
        type: 'Social Network',
        description:
          'Visualize Pins and inspire ideas with specialized tools to help you create, publish and report on your Pinterest content.',
        background: '#F3DEE1',
        link: '/integrations/pinterest',
      },
      {
        picture_url: networkImageGoogle,
        title: 'Google Business',
        type: 'Social Network',
        description:
          'Easily monitor and manage Google My Business reviews in a single stream so you can stay organized and informed, and respond quickly to every review.',
        background: '#FFFFFF',
        link: '/integrations/google',
      },
      {
        picture_url: networkImageReddit,
        title: 'Reddit',
        type: 'Social Listening',
        description:
          'Easily analyze relevant Reddit conversations to identify trends and sentiment, extract actionable insights, and stay ahead of the competition. ',
        background: '#FFEBE6',
        link: '/integrations/reddit',
      },
      {
        picture_url: networkImageSnapchat,
        title: 'Snapchat',
        type: 'Social Network',
        description:
          'Optimize and grow your end-to-end Snapchat marketing strategy with story and spotlight scheduling, and presentation-ready reports.',
        background: '#FFFC00',
        link: '/integrations/snapchat',
      },
      {
        picture_url: networkImageThreads,
        title: 'Threads',
        type: 'Social Network',
        description:
          'Elevate and scale your Threads social strategy with Vista Social’s intuitive planning, scheduling, engagement and reporting capabilities.',
        background: '#1d1d1d',
        link: '/integrations/threads',
      },
      {
        picture_url: networkImageTumblr,
        title: 'Tumblr',
        type: 'Social Network',
        description: `Vista Social's Tumblr integration includes planning, scheduling, publishing, analytics and response management tools for your Tumblr profiles.`,
        background: '#28394d',
        link: '/integrations/tumblr',
      },
      {
        picture_url: networkImageBluesky,
        title: 'Bluesky',
        type: 'Social Network',
        description: `Vista Social's Bluesky integration includes planning, scheduling, publishing, analytics and response management tools for your Bluesky profiles.`,
        background: 'linear-gradient(225deg, #ffffff 0%, #1083fe 100%)',
        link: '/integrations/bluesky',
      },
    ],
  },
  {
    id: WEBSITE_LINK_TRACKING_ID,
    title: WEBSITE_LINK_TRACKING_TITLE,
    description: `Vista’s website and link tracking integrations give you a clear breakdown of how your social content drives traffic to links that you share and helps you demonstrate the ROI of your social campaigns.`,
    networks: [
      {
        picture_url: networkImageGoogleAnalytics,
        title: 'Google Analytics',
        type: 'Website & Link Tracking',
        description:
          'Pull traffic data from your GA account into Vista Social to provide a clear breakdown of how your social content impacts traffic to your website.',
        background: '#FFF0E6',
        link:
          'https://support.vistasocial.com/hc/en-us/articles/21852721723803-Connecting-Google-Analytics-to-Vista-Social',
      },
      {
        picture_url: networkImageBitly,
        title: 'Bitly',
        type: 'Website & Link Tracking',
        description:
          'Embed shortened links into your social content using Bit.ly URLs branded to your company instead of a generic Bit.ly link.',
        background: '#FFF0E6',
        link: 'https://support.vistasocial.com/hc/en-us/articles/4413378621211-Link-Shortening',
      },
    ],
  },
  {
    id: REPUTATION_REVIEW_MANAGEMENT_ID,
    title: REPUTATION_REVIEW_MANAGEMENT_TITLE,
    description: `Vista’s review management helps you strengthen your online reputation by monitoring and managing reviews across your business and locations in one centralized stream.`,
    networks: [
      {
        picture_url: networkImageFacebook,
        title: 'Facebook',
        type: 'Social Network',
        description:
          'Vista’s Facebook integration includes publishing, boosting, analytics, reputation and response management tools for Pages and Messenger',
        background: 'linear-gradient(225deg, #E3F4FF 0%, #D0E5FF 100%)',
        link: '/integrations/facebook',
      },
      {
        picture_url: networkImageGoogle,
        title: 'Google Business',
        type: 'Social Network',
        description:
          'Easily monitor and manage Google My Business reviews in a single stream so you can stay organized and informed, and respond quickly to every review.',
        background: '#FFFFFF',
        link: '/integrations/google',
      },
      {
        picture_url: networkImageTripAdvisor,
        title: 'TripAdvisor',
        type: 'Reputation & Review Management',
        description:
          'Easily monitor and manage TripAdvisor reviews in a single stream so you can stay organized and informed, and respond quickly to every review.',
        background: '#D9F2EC',
        link: '/integrations/tripadvisor',
      },
      {
        picture_url: networkImageYelp,
        title: 'Yelp',
        type: 'Reputation & Review Management',
        description:
          'Actively moderate and respond to Yelp reviews within Vista in order to project a positive brand image and build stronger relationships with consumers.',
        background: '#FFE7E7',
        link: '/integrations/yelp',
      },
      {
        picture_url: networkImageTrustpilot,
        title: 'Trustpilot',
        type: 'Reputation & Review Management',
        description:
          'Actively moderate and respond to Trustpilot reviews within Vista in order to project a positive brand image and build stronger relationships with consumers.',
        background: '#E4E4F0',
        link: '/integrations/trustpilot',
      },

      {
        picture_url: networkImageOpenTable,
        title: 'OpenTable',
        type: 'Reputation & Review Management',
        description:
          'Easily monitor and manage OpenTable reviews in a single stream so you can stay organized and informed, and respond quickly to every review.',
        background: '#FFEBED',
        link: '/integrations/opentable',
      },
    ],
  },

  // {
  //   id: SOCIAL_LISTENING_ID,
  //   title: SOCIAL_LISTENING_TITLE,
  //   description: `Vestibulum imperdiet nibh scelerisque dignissim ut eu in. Morbi semper vestibulum a cursus elit odio ipsum venenatis netus. Orci diam integer cras.`,
  //   networks: [
  //     {
  //       picture_url: networkImageReddit,
  //       title: 'Reddit',
  //       type: 'Social Listening',
  //       description:
  //         'Easily analyze relevant Reddit conversations to identify trends and sentiment, extract actionable insights, and stay ahead of the competition. ',
  //       background: '#FFEBE6',
  //       link: '/integrations/reddit',
  //     },
  //   ],
  // },
  {
    id: DAM_ID,
    title: DAM_TITLE,
    description: `Vista’s integrations with design tools and royalty free image and video repositories make finding and creating great graphics to support content a breeze.`,
    networks: [
      {
        picture_url: networkImageCanva,
        title: 'Canva',
        type: DAM_TITLE,
        description:
          'Share Canva-made content! Easily connect your Canva, create or import designs, and publish them to your social media channels.',
        background: '#E4E4F0',
        link: 'https://support.vistasocial.com/hc/en-us/articles/7076524274715-Design-images-with-Canva',
      },
      {
        picture_url: networkImageDropbox,
        title: 'Dropbox',
        type: DAM_TITLE,
        description:
          'Import all the latest and greatest approved content from your team’s Dropbox directly into Vista Social.',
        background: '#cedbef',
        link: 'https://support.vistasocial.com/hc/en-us/articles/17286894494619-Media-Library-Overview',
      },
      {
        picture_url: networkImageGoogleDrive,
        title: 'Google Drive',
        type: DAM_TITLE,
        description:
          'Import all the latest and greatest approved content from your team’s Google Drive directly into Vista Social.',
        background: '#f0f1f2',
        link: 'https://support.vistasocial.com/hc/en-us/articles/17286894494619-Media-Library-Overview',
      },
      {
        picture_url: networkImageOneDrive,
        title: 'One Drive',
        type: DAM_TITLE,
        description:
          'Import all the latest and greatest approved content from your team’s One Drive directly into Vista Social.',
        background: '#d0e4f1',
        link: 'https://support.vistasocial.com/hc/en-us/articles/17286894494619-Media-Library-Overview',
      },
      {
        picture_url: networkImageGiphy,
        title: 'Giphy',
        type: DAM_TITLE,
        description:
          'Search for and import your favorite gifs from Giphy directly into your Vista Social media library.',
        background: '#565763',
        link: 'https://support.vistasocial.com/hc/en-us/articles/17286894494619-Media-Library-Overview',
      },
      {
        picture_url: networkImagePexels,
        title: 'Pexels',
        type: DAM_TITLE,
        description:
          'Search for and import royalty free images and videos Pexels directly into your Vista Social media library.',
        background: '#d5eee9',
        link: 'https://support.vistasocial.com/hc/en-us/articles/17286894494619-Media-Library-Overview',
      },
      {
        picture_url: networkImageUnsplash,
        title: 'Unsplash',
        type: DAM_TITLE,
        description:
          'Search for and import royalty free images and videos Unsplash directly into your Vista Social media library.',
        background: '#f3f3f3',
        link: 'https://support.vistasocial.com/hc/en-us/articles/17286894494619-Media-Library-Overview',
      },
    ],
  },
  {
    id: INTEGRATION_API_ID,
    title: INTEGRATION_API_TITLE,
    description: `Easily connect your Vista’s account with thousands of other applications for limitless integration opportunities to implement automated workflows to further streamline your work.`,
    networks: [
      {
        picture_url: networkImageZapier,
        title: 'Zapier',
        type: 'Integration and API',
        description:
          'Easily connect to thousands of other apps on <a href="https://zapier.com/apps/vista-social/integrations" target="_blank">Zapier</a> to help deliver data and alerts to and from your Vista Social account.',
        background: '#FFF0E6',
        link: 'https://support.vistasocial.com/hc/en-us/articles/10598636403227-Zappier-Integration',
      },
      {
        picture_url: networkImageMake,
        title: 'Make',
        type: 'Integration and API',
        description:
          'Easily connect to thousands of other apps on <a href="https://www.make.com/en/integrations/vista-social" target="_blank">Make.com</a> to help deliver data and alerts to and from your Vista Social account.',
        background: '#decfeb',
        link: 'https://support.vistasocial.com/hc/en-us/articles/21600307690523-Make-com-Integration',
      },
    ],
  },
  {
    id: WORKFLOW_ID,
    title: WORKFLOW_TITLE,
    description: `Vista Soical's flexible integrations can fit any team's content creation, approval workflow and overall marketing process.`,
    networks: [
      {
        picture_url: networkImageSlack,
        title: 'Slack',
        type: WORKFLOW_TITLE,
        background: '#e4d4e5',
        description:
          'Stay up to date on your Vista notifications in real-time with customized Slack notifications. Create multiple channels to stay organized and never miss a beat!.',
        link: 'https://support.vistasocial.com/hc/en-us/articles/23097327081755-Slack-Integration',
      },
    ],
  },
  {
    id: BIO_ID,
    title: BIO_TITLE,
    description: `Flexible landing pages that brings together all of your best content in one place.`,
    networks: [
      {
        picture_url: networkImageVistaPage,
        title: 'Vista Page',
        type: BIO_TITLE,
        background: '#0655ba',
        description: 'Create a stunning fully-customizable link in bio and landing pages for your business or brand. ',
        link: 'https://vistasocial.com/vista-page/',
      },
    ],
  },
]

INTEGRATION_ITEMS.forEach((item) => {
  item.networks.sort((item1, item2) => (item1.title > item2.title ? 1 : -1))
})
const Integrations = () => {
  const [activeMenuStep, setActiveMenuStep] = useState(CATEGORIES[0])

  // const handleScroll = () => {
  // //  const windowTop = window.scrollY
  //  // const windowBottom = windowTop + window.innerHeight
  //   const newActiveAnchor = CATEGORIES.find((anchor) => {
  //     const anchorElement = document.getElementById(`${anchor.id}_content`)
  //     return !!anchorElement
  //   })
  //   if (newActiveAnchor) {
  //     console.log('new', newActiveAnchor)
  //     setActiveMenuStep(newActiveAnchor)
  //   }
  // }

  useEffect(() => {
    const handleScroll = () => {
      const windowTop = window.scrollY
      const windowBottom = windowTop + window.innerHeight

      const newActiveAnchor = CATEGORIES.find(({ id }) => {
        const anchorElement = typeof document !== 'undefined' ? document.getElementById(`${id}_content`) : null

        if (anchorElement) {
          const rect = anchorElement.getBoundingClientRect()
          const elementTop = rect.top + windowTop
          const elementBottom = elementTop + rect.height
          return elementTop < windowBottom && elementBottom > windowTop + window.innerHeight * 0.2
        }
        return false
      })

      if (newActiveAnchor) {
        setActiveMenuStep(newActiveAnchor)
      }
    }

    window.addEventListener('scroll', handleScroll)

    handleScroll()

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [setActiveMenuStep])

  const handleClickCategoryMenuItem = ({ id }) => {
    const el = typeof document !== 'undefined' ? document.getElementById(`${id}_content`) : null
    if (el) {
      window.scrollTo({ top: el.offsetTop - 70, behavior: 'smooth' })
    }
  }

  return (
    <App fullHeader>
      <SEO
        title="Powerful social media integrations"
        description="Vista Social centralizes publishing, monitoring, engagement, listening, landing pages and analytics across the social platforms and customer management tools that power your social business."
        path="/integrations/"
      />

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex flexDirection="column" alignItems="center">
            <Grid
              mt="xl"
              mb="xxl"
              pt={{ mobile: 0, desktop: 'm' }}
              gridTemplateColumns={{ mobile: '1fr', desktop: '1fr 1fr' }}
              gridGap="l"
              width="100%"
              alignItems="center"
            >
              <Flex width="100%" justifyContent="center" alignItems="center">
                <Flex flexDirection="column">
                  <Flex width="100%" justifyContent="center" flexDirection="column">
                    <Flex
                      flexDirection={{ mobile: 'row', desktop: 'column' }}
                      flexWrap="wrap"
                      justifyContent="center"
                      maxWidth={{ mobile: '100%', desktop: '465px' }}
                    >
                      <H1
                        fontSize="4xl"
                        fontWeight="bold"
                        color={COLOR_CONSTANTS.DENIM}
                        textAlign={{ mobile: 'center', desktop: 'left' }}
                      >
                        Powerful social media{' '}
                        <H1
                          as="span"
                          fontSize="4xl"
                          fontWeight="bold"
                          color="primary"
                          textAlign={{ mobile: 'center', desktop: 'left' }}
                        >
                          Integrations
                        </H1>
                      </H1>
                    </Flex>
                    <H2
                      mt="m"
                      pt="s"
                      color="secondaryText"
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                      fontWeight="normal"
                      fontSize="m"
                      lineHeight={lineHeights.xxl}
                    >
                      Vista Social centralizes publishing, monitoring, engagement, listening, landing pages and
                      analytics across the social platforms and customer management tools that power your social
                      business.
                    </H2>
                    <SelectPlanButton />
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                ml="auto"
                mr={{ mobile: 'auto', desktop: 0 }}
                mt={{ mobile: 'l', desktop: 0 }}
                width={{ mobile: '100%', desktop: '458px', desktopWide: '567px' }}
                height={{ mobile: '100%', desktop: '418px', desktopWide: '517px' }}
                maxWidth="567px"
                maxHeight="517px"
              >
                <LocalImage
                  src={imageHeader}
                  mx="auto"
                  width="100%"
                  height="100%"
                  alt="Powerful social media integrations"
                  preload="image"
                  loading="lazy"
                />
              </Flex>
            </Grid>
          </Flex>
        </Container>
      </Wrapper>

      <Box bg={COLOR_CONSTANTS.SALT}>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex zIndex="1" alignItems={{ mobile: 'center', desktop: 'flex-start' }} flexDirection="column" mx="auto">
            <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM}>
              All Integrations
            </H3>
            <Text mt="m" pt="s" color="secondaryText" fontSize="l" textAlign={{ mobile: 'center', desktop: 'left' }}>
              No more jumping between platforms. Access all the content and data your organization needs! Vista Social
              supports most social media networks, allowing you to manage your pages and profiles in one place.
            </Text>
          </Flex>
          <Grid
            mt="xl"
            gridTemplateColumns={{ mobile: '1fr', desktop: '255px 1fr' }}
            gridGap="l"
            alignItems="start"
            maxWidth={CONTAINER_MAX_WIDTH}
            width="100%"
          >
            <StyledCategoriesWrapper display={{ mobile: 'none', desktop: 'block' }} position="relative">
              <Text fontSize="l" fontWeight="bold" color="secondaryText" textAlign="left">
                Category
              </Text>
              <Flex mt="m" flexDirection="column">
                {CATEGORIES.map(({ id, title }) => {
                  const isActive = activeMenuStep ? id === activeMenuStep.id : false

                  return (
                    <StyledCategoryItem
                      key={id}
                      id={id}
                      bg={isActive ? COLOR_CONSTANTS.BABY_BLUE_EYES : 'transparent'}
                      onClick={() => handleClickCategoryMenuItem({ id })}
                    >
                      <Text
                        my="xxs"
                        color={isActive ? COLOR_CONSTANTS.DENIM : 'secondaryText'}
                        fontWeight={isActive ? 'medium' : 'normal'}
                        textAlign="left"
                      >
                        {title}
                      </Text>
                    </StyledCategoryItem>
                  )
                })}
              </Flex>
            </StyledCategoriesWrapper>

            <StyledIntegrationItemsWrapper>
              {INTEGRATION_ITEMS.map(({ id, title, description, networks }) => {
                return (
                  <Flex key={id} flexDirection="column" id={`${id}_content`}>
                    <Text fontSize="xxxl" color={COLOR_CONSTANTS.DENIM} fontWeight="medium" textAlign="left">
                      {title}
                    </Text>
                    <Text mt="s" fontSize="m" color="secondaryText" textAlign="left">
                      {description}
                    </Text>
                    <Grid
                      mt="l"
                      zIndex="1"
                      gridGap="l"
                      gridTemplateColumns={{ mobile: 'repeat(1, 1fr)', tablet: 'repeat(2, 1fr)' }}
                    >
                      {networks.map(({ title, description, type, picture_url, background, link }) => (
                        <StyledNetworkItemWrapper
                          flexDirection="column"
                          key={title}
                          maxWidth={{ mobile: '100%', tablet: '445px' }}
                          width="100%"
                          onClick={() => {
                            window.open(link, '_self')
                          }}
                        >
                          <Flex flexDirection="column" p="s">
                            <Flex alignItems="center">
                              <StyledNetworkWrapper minWidth="60px" width="60px" height="60px" background={background}>
                                <LocalImage src={picture_url} alt={title} width="32px" height="32px" />
                              </StyledNetworkWrapper>
                              <Flex flexDirection="column" ml="s" pl="xs" width="100%">
                                <Flex justifyContent="space-between" alignItems="center" width="100%">
                                  <Text color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="xl" textAlign="left">
                                    {title}
                                  </Text>
                                  <Text as="a" href={link}>
                                    <LocalImage src={imageDoubleArrowRightBlue} />
                                  </Text>
                                </Flex>
                                <Text fontSize="s" color="secondaryText" textAlign="left">
                                  {type}
                                </Text>
                              </Flex>
                            </Flex>
                            <Text
                              mt="m"
                              fontSize="m"
                              color="secondaryText"
                              textAlign="left"
                              dangerouslySetInnerHTML={{ __html: description }}
                            />
                          </Flex>
                        </StyledNetworkItemWrapper>
                      ))}
                    </Grid>
                  </Flex>
                )
              })}
            </StyledIntegrationItemsWrapper>
          </Grid>
        </Container>
      </Box>

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
            Frequently Asked{' '}
            <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
              Questions
            </H1>
          </H3>
          <Flex flexDirection="column" mt="xl" width="100%">
            <GridDropdown faqs={FREQUENTLY_ASKED_QUESTIONS.all.slice(0, 3)} />
            <Flex mt="l" justifyContent="flex-end">
              <StyledLinkText as="a" href="/faq" textAlign="left">
                <Text fontSize="l" fontWeight="medium" textAlign="left" color="primary" as="span">
                  ALL OUR FAQS
                </Text>
                <LocalImage src={imageDoubleArrowRightBlue} mt="-2px" />
              </StyledLinkText>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <GetStartedSection />

      <Footer />
    </App>
  )
}

export default Integrations
