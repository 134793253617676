import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box } from 'components/Layout'
import { space } from 'theme'

const DEFAULT_CONTAINER_WIDTH = '1200px'

const Container = styled(Box)`
  max-width: ${({ maxWidth }) => maxWidth || DEFAULT_CONTAINER_WIDTH};
  padding-left: ${({ pl }) => pl};
  padding-right: ${({ pr }) => pr};
  margin-left: auto;
  margin-right: auto;
`

Container.defaultProps = {
  pl: space.m,
  pr: space.m,
}

Container.propTypes = {
  maxWidth: PropTypes.string,
  pl: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  pr: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default Container
